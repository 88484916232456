import React from "react";
import { Container, Row, Col, Card, CardHeader, CardBody, FormSelect, Button } from "shards-react";
import api from "../../api";
import { Link } from "react-router-dom";
import { API_URL } from '../../config';
import PageTitle from "../../components/common/PageTitle";

class Statistic extends React.Component 
{
  constructor(props) {
    super(props)

    this.state = {
      statisticData: [],
      arrayYear: [],
      filterMonth: new Date().getMonth() + 1,
      filterYear: new Date().getFullYear(),
    }
  }

  componentWillMount() {
    let currentYear = new Date().getFullYear()
    let tempYears = []
    for (let i = currentYear - 10; i < currentYear + 1; i++) {
      tempYears.push(i)
    }
    this.setState({
      arrayYear: tempYears,
      filterMonth: this.props.location.state.month,
      filterYear: this.props.location.state.year
    }, this.fetch)
  }

  fetch() {
    api.get('/time_sheet/statistics', {
      params: {
        month: this.state.filterMonth,
        year: this.state.filterYear,
        account_id: this.props.location.state.account_id
      }
    }).then((res) => {
      if (res.status) {
        this.setState({
          statisticData: res.data
        })
      } else {
        alert(res.message)
      }
    })
  }

  handleExport() {
    const { filterMonth, filterYear } = this.state
    const lastDate = new Date(filterYear, filterMonth, 0)
    this.setState({
      loading: true
    })
    api.get('/time_sheet/export', {
      params: {
        from: this.state.filterYear + '-' + this.state.filterMonth + '-1',
        to: this.state.filterYear + '-' + this.state.filterMonth + '-' + lastDate.getDate(),
        account_id: this.props.location.state.account_id
      }
    }).then((res) => {
      this.setState({
        loading: false
      })
    }, (err) => {
      this.setState({
        loading: false
      })
    })
  }
  
  render() {
    let { statisticData, arrayYear } = this.state;

    return (
      <Container fluid className="main-content-container px-4">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          <Col>
            <PageTitle sm="4" title="List Items" subtitle="Statistic Detail" className="text-sm-left" />
          </Col>
          <Col>
            <Button theme="success" style={{ float: 'right' }} onClick={this.handleExport.bind(this)}>Export Data</Button>
          </Col>
        </Row>

        <Row>
          <Col>
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <Row>
                  <Col>
                    <h6 className="m-0">Statistic Detail Table</h6>
                  </Col>
                  <Col style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ marginRight: 10 }}>Month:</span>
                    <FormSelect value={this.state.filterMonth} onChange={(e) => this.setState({ filterMonth: e.target.value }, this.fetch)}>
                      {
                        [1,2,3,4,5,6,7,8,9,10,11,12].map((item) => (
                          <option key={item} value={item}>{item}</option>
                        )) 
                      }
                    </FormSelect>
                    <span style={{ marginRight: 10, marginLeft: 10 }}>Year:</span>
                    <FormSelect value={this.state.filterYear} onChange={(e) => this.setState({ filterYear: e.target.value }, this.fetch)}>
                      {
                        arrayYear.map((item) => (
                          <option key={item} value={item}>{item}</option>
                        )) 
                      }
                    </FormSelect>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="p-0 pb-3">
                <table className="table mb-0">
                  <thead className="bg-light">
                    <tr>
                      <th scope="col" className="border-0">
                        #
                      </th>
                      <th scope="col" className="border-0">
                        Date
                      </th>
                      <th scope="col" className="border-0">
                        Checkin Time
                      </th>
                      <th scope="col" className="border-0">
                        Checkout Time
                      </th>
                      <th scope="col" className="border-0">
                        Checkin Location
                      </th>
                      <th scope="col" className="border-0">
                        Checkout Location
                      </th>
                      <th scope="col" className="border-0">
                        Late Reason
                      </th>
                      <th scope="col" className="border-0">
                        Early Reason
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      statisticData.map((item, index) => (
                        <tr key={index}>
                          <td>{ index + 1 }</td>
                          <td>{ item.date }</td>
                          <td>{ item.checkin_time }</td>
                          <td>{ item.checkout_time }</td>
                          <td>
                            {
                              item.checkin_lat && item.checkin_lng ?
                              <Link to={{ pathname: API_URL + `/map?lat=${item.checkin_lat}&lng=${item.checkin_lng}` }} target="_blank">
                                <span style={styles.view}>Xem vị trí</span>
                              </Link>
                              : null
                            }
                          </td>
                          <td>
                            {
                              item.checkout_lat && item.checkout_lng ?
                                <Link to={{ pathname: API_URL + `/map?lat=${item.checkout_lat}&lng=${item.checkout_lng}` }} target="_blank">
                                  <span style={styles.view}>Xem vị trí</span>
                                </Link>
                              : null
                            }
                          </td>
                          <td>{ item.late_reason }</td>
                          <td>{ item.early_reason }</td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    )
  }
}

const styles = {
  view: {
    cursor: 'pointer',
    fontSize: 14,
  },
}

export default Statistic;
