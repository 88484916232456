import React from "react";
import PropTypes from "prop-types";
import {
  Container,
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormGroup,
  FormInput,
  FormSelect,
  FormRadio,
  Button
} from "shards-react";
import api from "../../api";
import { Link } from "react-router-dom";

class NewUser extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {},
    }
  }

  handleCreate() {
    let { user } = this.state;
    api.post("/account", user).then((res) => {
      if(res.status) {
        alert('Create Successful!')
      }
    })
  }

  render() {
    let {user} = this.state;

    return (
      <Container fluid className="main-content-container py-4 px-4">
        <Card small>
          <CardHeader className="border-bottom">
            <h6 className="m-0">{this.props.title}</h6>
          </CardHeader>
          <ListGroup flush>
            <ListGroupItem className="p-3">
              <Row>
                <Col>
                  <Form>
                    <Row form>
                        {/* Fullname */}
                        <Col md="6" className="form-group">
                            <label htmlFor="feFullname">Fullname</label>
                            <FormInput
                                id="feFullname"
                                placeholder="Fullname"
                                value={user.name || ''}
                                onChange={(e) => this.setState({ user: { ...user, name: e.target.value } })}
                            />
                        </Col>
                        {/* Phone Number */}
                        <Col md="6" className="form-group">
                            <label htmlFor="fePhoneNumber">Phone Number</label>
                            <FormInput
                                id="fePhoneNumber"
                                placeholder="Phone Number"
                                value={user.phone || ''}
                                onChange={(e) => this.setState({ user: { ...user, phone: e.target.value } })}
                            />
                        </Col>
                    </Row>
                    <Row form>
                        {/* Role */}
                        <Col md="6" className="form-group">
                            <label htmlFor="feRole">Role</label>
                            <FormSelect id="feRole" onChange={(e) => this.setState({ user: { ...user, role: e.target.value } })}>
                                <option value="EMPLOYEE">Employee</option>
                                <option value="INTERSHIP">Intership</option>
                                <option value="TEAM_LEADER">Team Leader</option>
                                <option value="ADMIN">Admin</option>
                                <option value="DEVELOPER">Developer</option>
                            </FormSelect>
                        </Col>
                        {/* Password */}
                        <Col md="6" className="form-group">
                            <label htmlFor="fePassword">Password</label>
                            <FormInput
                                type="password"
                                id="fePassword"
                                placeholder="Password"
                                value={user.password || ''}
                                onChange={(e) => this.setState({ user: { ...user, password: e.target.value } })}
                                autoComplete="current-password"
                            />
                        </Col>
                    </Row>
                    <Row form>
                        {/* TimeCheckin */}
                        <Col md="6" className="form-group">
                            <label htmlFor="feTimeCheckin">Time Checkin</label>
                            <FormInput
                                id="feTimeCheckin"
                                placeholder="Time Checkin"
                                value={user.time_in || ''}
                                onChange={(e) => this.setState({ user: { ...user, time_in: e.target.value } })}
                            />
                        </Col>
                        {/* Phone Number */}
                        <Col md="6" className="form-group">
                            <label htmlFor="feTimeCheckout">Time Checkout</label>
                            <FormInput
                                id="feTimeCheckout"
                                placeholder="Time Checkout"
                                value={user.time_out || ''}
                                onChange={(e) => this.setState({ user: { ...user, time_out: e.target.value } })}
                            />
                        </Col>
                    </Row>
                    <FormGroup>
                        <FormRadio
                            inline
                            name="active"
                            checked={user.active === true}
                            onChange={() => this.setState({ user: { ...user, active: true } })}
                        >
                            Active
                        </FormRadio>
                        <FormRadio
                            inline
                            name="active"
                            checked={user.active === false}
                            onChange={() => this.setState({ user: { ...user, active: false } })}
                        >
                            Unactive
                        </FormRadio>
                    </FormGroup>

                    <Button theme="info" outline className="mr-2" tag={Link} to="/users">Go Back</Button>
                    <Button theme="accent" onClick={() => this.handleCreate()}>Create User</Button>
                  </Form>
                </Col>
              </Row>
            </ListGroupItem>
          </ListGroup>
        </Card>
      </Container>
    )
  }
};

NewUser.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string
};

NewUser.defaultProps = {
  title: "Account Details"
};

export default NewUser;
