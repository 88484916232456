export default function() {
  return [
    {
      title: "Dashboard",
      to: "/dashboard",
      htmlBefore: '<i class="material-icons">dashboard</i>',
      htmlAfter: ""
    },
    {
      title: "Statistic",
      htmlBefore: '<i class="material-icons">table_chart</i>',
      to: "/statistic",
    },
    {
      title: "Users",
      htmlBefore: '<i class="material-icons">people</i>',
      to: "/users",
    },
  ];
}
