import React from "react";
import { Container, Row, Col, Card, CardHeader, CardBody, FormSelect, Button } from "shards-react";
import api from "../api";
import { Link } from "react-router-dom";
import LoadingOverlay from 'react-loading-overlay';
import PageTitle from "../components/common/PageTitle";

class Statistic extends React.Component 
{
  constructor(props) {
    super(props)

    this.state = {
      statisticData: [],
      arrayYear: [],
      filterMonth: new Date().getMonth() + 1,
      filterYear: new Date().getFullYear(),
      loading: false,
    }
  }

  componentWillMount() {
    let currentYear = new Date().getFullYear()
    let tempYears = []
    for (let i = currentYear - 10; i < currentYear + 1; i++) {
      tempYears.push(i)
    }
    this.setState({
      arrayYear: tempYears
    })
    this.fetch()
  }

  fetch() {
    api.get('/time_sheet/statistics', {
      params: {
        month: this.state.filterMonth,
        year: this.state.filterYear,
      }
    }).then((res) => {
      if (res.status) {
        this.setState({
          statisticData: res.data
        })
      } else {
        alert(res.message)
      }
    })
  }
  
  handleExport() {
    const { filterMonth, filterYear } = this.state
    const lastDate = new Date(filterYear, filterMonth, 0)
    this.setState({
      loading: true
    })
    api.get('/time_sheet/export', {
      params: {
        from: this.state.filterYear + '-' + this.state.filterMonth + '-1',
        to: this.state.filterYear + '-' + this.state.filterMonth + '-' + lastDate.getDate(),
      }
    }).then((res) => {
      this.setState({
        loading: false
      })
    }, (err) => {
      this.setState({
        loading: false
      })
    })
  }

  render() {
    let { statisticData, arrayYear, loading } = this.state;

    return (
      <LoadingOverlay
        active={loading}
        spinner
        text='Waiting...'>
        <Container fluid className="main-content-container px-4">
          {/* Page Header */}
          <Row noGutters className="page-header py-4">
            <Col>
              <PageTitle sm="4" title="List Items" subtitle="Statistic" className="text-sm-left" />
            </Col>
            <Col>
              <Button theme="success" style={{ float: 'right' }} onClick={this.handleExport.bind(this)}>Export Data</Button>
            </Col>
          </Row>

          <Row>
            <Col>
              <Card small className="mb-4">
                <CardHeader className="border-bottom">
                  <Row>
                    <Col>
                      <h6 className="m-0">Statistic Table</h6>
                    </Col>
                    <Col style={{ display: 'flex', alignItems: 'center' }}>
                      <span style={{ marginRight: 10 }}>Month:</span>
                      <FormSelect value={this.state.filterMonth} onChange={(e) => this.setState({ filterMonth: e.target.value }, this.fetch)}>
                        {
                          [1,2,3,4,5,6,7,8,9,10,11,12].map((item) => (
                            <option key={item} value={item}>{item}</option>
                          )) 
                        }
                      </FormSelect>
                      <span style={{ marginRight: 10, marginLeft: 10 }}>Year:</span>
                      <FormSelect value={this.state.filterYear} onChange={(e) => this.setState({ filterYear: e.target.value }, this.fetch)}>
                        {
                          arrayYear.map((item) => (
                            <option key={item} value={item}>{item}</option>
                          )) 
                        }
                      </FormSelect>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="p-0 pb-3">
                  <table className="table mb-0">
                    <thead className="bg-light">
                      <tr>
                        <th scope="col" className="border-0">
                          #
                        </th>
                        <th scope="col" className="border-0">
                          Name
                        </th>
                        <th scope="col" className="border-0">
                          Phone
                        </th>
                        <th scope="col" className="border-0">
                          Timekeeping Numbers
                        </th>
                        <th scope="col" className="border-0">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        statisticData.map((item, index) => (
                          <tr key={index}>
                            <td>{ index + 1 }</td>
                            <td>{ item.name }</td>
                            <td>{ item.phone }</td>
                            <td>{ item.count }</td>
                            <td>
                              <Link to={{
                                pathname: "statistic-detail",
                                state: {
                                  month: this.state.filterMonth,
                                  year: this.state.filterYear,
                                  account_id: item.id
                                }
                              }}>
                                <span style={styles.view}>Xem chi tiết</span>
                              </Link>
                            </td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </LoadingOverlay>
    )
  }
}

const styles = {
  view: {
    cursor: 'pointer',
    fontSize: 14,
  },
}

export default Statistic;
