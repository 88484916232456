import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col, Button } from "shards-react";
import api from "../api";
import mqtt from "../utils/mqtt";

import PageTitle from "../components/common/PageTitle";
import SmallStats from "../components/common/SmallStats";
import TemperatureChart from "../components/dashboard/Temperature";
import HumidityChart from "../components/dashboard/Humidity";

class Dashboard extends React.Component
{
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <Container fluid className="main-content-container px-4">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          <PageTitle title="Home Overview" subtitle="Dashboard" className="text-sm-left mb-3" />
        </Row>

      </Container>
    )
  }
}

Dashboard.propTypes = {
  /**
   * The small stats dataset.
   */
  smallStats: PropTypes.array
};

export default Dashboard;
